import type { ComponentProps } from 'react';
import {
  Notice,
  NoticeDescription,
  NoticeTitle,
} from '@/pages/QuickCreate/components/Notice';
import { getErrorMessage } from './getErrorMessage';
import type { ErrorInput, PromptType } from './types';

type Props = ComponentProps<typeof Notice> & {
  error?: ErrorInput;
  type?: PromptType;
};

export const QuickCreateErrorMessage = ({ error, type, ...props }: Props) => {
  const { title, message } = getErrorMessage(error, type);

  return (
    <Notice {...props}>
      <NoticeTitle fontWeight={message ? undefined : 'normal'}>
        {title}
      </NoticeTitle>
      {message && (
        <NoticeDescription dangerouslySetInnerHTML={{ __html: message }} />
      )}
    </Notice>
  );
};
