import { Alert, AlertDescription, type AlertProps, AlertTitle } from '@/ui';

export const Notice = (props: AlertProps) => {
  return (
    <Alert
      alignItems="flex-start"
      flexDir="column"
      maxW="800px"
      mx="auto"
      variant="purple"
      {...props}
    />
  );
};

export const NoticeTitle = AlertTitle;
export const NoticeDescription = AlertDescription;
